import React, { Component, Fragment } from "react";

export default class HomePlaceholder extends Component {

    displayHomeComponent = () => {
        return (
            <Fragment>
                <div className="wrapper">
                    <header>
                        <div className="presentation">
                            <div className="main-nav">
                                <div className="divider-circle"></div>
                                <nav className="container">
                                    <div className="logo pull-left">
                                        <a href="index.html">
                                            <h1 className="logo-text"><span className="text-highlight">EGS</span></h1>
                                        </a>
                                    </div>
                                    <div className="pull-right" id="navigation-box">
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </header>
                    <div className="home-title-wrapper">
                        <div className="pattern-light-wrapper">
                            <div className="container">
                                <h2 className="page-title-main">Bridging the gap</h2>
                                <h3 className="page-title-secondary">and bringing services to rural and underserved areas.</h3>
                            </div>
                            <div className="decorative-lines"></div>
                            <div className="container">
                                <div className="circle-info-buttons">
                                    <div className="circle-info-wrapper">
                                        <div className="circle-info">
                                            <div className="circle-info__front">
                                                <h4 className="circle-info__title">Connect</h4>
                                            </div>
                                            <div className="circle-info__back">
                                                <h4 className="circle-info__title">Connect</h4>
                                                <p className="circle-info__text hidden-xs">Provide internet connectivity services.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="circle-info-wrapper">
                                        <div className="circle-info">
                                            <div className="circle-info__front">
                                                <h4 className="circle-info__title">Educate</h4>
                                            </div>
                                            <div className="circle-info__back">
                                                <h4 className="circle-info__title">Educate</h4>
                                                <p className="circle-info__text hidden-xs">Using technology to enhance child learning.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="circle-info-wrapper">
                                        <div className="circle-info">
                                            <div className="circle-info__front">
                                                <h4 className="circle-info__title">Sustain</h4>
                                            </div>
                                            <div className="circle-info__back">
                                                <h4 className="circle-info__title">Sustain</h4>
                                                <p className="circle-info__text hidden-xs">Provide energy solutions such as solor powered light.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="divider-simple"></div>
                        </div>
                    </div>
                    <div>
                        <div className="pattern-footer-wrapper">
                            <br />
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
    render() {
        return this.displayHomeComponent();
    }
}