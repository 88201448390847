import React, { Component } from "react";
export default class Footer extends Component {

    render() {
        return (
            <footer className="footer-wrapper">
                <div className="footer-secondary">
                    <div className="divider-circle"></div>
                    <div className="container">
                        <ul className="social"></ul>
                        <div className="copyright">
                            <small className="small">&copy; 2022 - Elerai Global Services</small>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
